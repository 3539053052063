import { useContext, useEffect, useMemo, useState } from "react";
import {
	getFiltersAndSearchQueryFromUrl,
	LAYOUT,
	makeApiUrl,
	sanitizeFilterValues,
} from "util/common";
import { useHttp } from "./useHttp";
import { useRouter } from "next/router";
import { CacheContext } from "components/contexts/CacheContext";
import { mappedFilters } from "util/commonFilters";

export function useServerTable({
	requestPath,
	autoload = false,
	initialFilters = [],
	initialSortBy = "",
	pageSize: initialPageSize = 15,
	infiniteScrolling = false,
	userEmail = false,
	oData = {},
	initialSearchQuery = null,
	resetTotalItems = false,
	withInitialFilters = true,
	initialLayout = LAYOUT.LIST,
	layoutOptions = [LAYOUT.LIST], // maximum 2 options
}) {
	const { $post } = useHttp();
	const router = useRouter();
	const cache = useContext(CacheContext);
	const {
		filters: filtersFromUrl,
		searchQuery: searchQueryFromUrl,
		sortBy: sortByFromUrl,
	} = useMemo(() => {
		if (!withInitialFilters || !router.isReady)
			return { filters: [], searchQuery: null, sortBy: [] };
		return getFiltersAndSearchQueryFromUrl(router, cache);
	}, [withInitialFilters, router.isReady]);

	const [ready, setReady] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [items, setItems] = useState([]);
	const [pageNum, setPageNum] = useState(1);
	const [pageSize, setPageSize] = useState(initialPageSize);
	const [searchQuery, setSearchQuery] = useState(
		initialSearchQuery ?? searchQueryFromUrl ?? null,
	);
	const [filters, setFilters] = useState(
		initialFilters.length > 0 ? initialFilters : filtersFromUrl ?? [],
	);
	const [sortBy, setSortBy] = useState(
		!!sortByFromUrl?.length
			? sortByFromUrl
			: !!initialSortBy
			? [initialSortBy]
			: [],
	);
	const [totalItems, setTotalItems] = useState(0);
	const [selectedIndexes, setSelectedIndexes] = useState([]);
	const [lastUpdateAt, setLastUpdateAt] = useState(null);
	const [addtionalParams, setAdditionalParams] = useState(oData);
	const [totalPages, setTotalPages] = useState(1);
	const [layout, setLayout] = useState(initialLayout);
	const [isFirstLoad, setIsFirstLoad] = useState(true);
	const selectedItems = useMemo(
		() => selectedIndexes.filter(i => !!items[i]).map(i => items[i]),
		[selectedIndexes],
	);

	useEffect(() => {
		if (!router.isReady) return;
		if (withInitialFilters) {
			if (
				initialFilters.length === 0 &&
				filtersFromUrl &&
				filtersFromUrl.length > 0
			) {
				setFilters(filtersFromUrl);
			}

			if (initialSearchQuery === null && searchQueryFromUrl !== undefined) {
				setSearchQuery(searchQueryFromUrl);
			}
		}
		if (!autoload) {
			return;
		}
		void fetchData();
	}, []);

	const fetchData = async (reqData, overrideItems = false) => {
		reqData = !!reqData
			? reqData
			: { pageNum, pageSize, searchQuery, filters, sortBy, addtionalParams };

		const url = makeApiUrl(requestPath);
		const params = buildRequestData(reqData);

		if (userEmail) {
			params.email = userEmail;
		}

		setError(null);
		setLoading(true);
		// setPageNum(params.tbl?.pn || 1);
		const res = await $post(url, { ...params });
		setLoading(false);
		setSelectedIndexes([]);

		if (res.ok) {
			handleServerResponse(res, overrideItems);
		} else {
			if (!res?.ok && res?.error?.code === "404") {
				router.push("/products");
			}
			setError(res.error);
		}
		setReady(true);
		setIsFirstLoad(false);
		return res;
	};

	const handleServerResponse = (res, overrideItems) => {
		let resItems = res?.data?.items || [];
		const isLocationFilter = addtionalParams.hasOwnProperty("latitude");

		if (!!infiniteScrolling) {
			setItems(_items =>
				!!overrideItems ? resItems : [..._items, ...resItems],
			);
		} else {
			setItems(resItems);
		}
		const tbl = res.data.tbl;
		if (!tbl?.totalItems && resetTotalItems) {
			setTotalItems(0);
		}
		if (!!tbl) {
			setPageNum(tbl.page_num);
			setPageSize(tbl.page_size);
			setSearchQuery(tbl.search_query);
			// setSortBy(tbl.sort_by);
			setTotalItems(tbl.total_items || 0);
			setLastUpdateAt(tbl?.last_update_at);
			const tp =
				tbl.page_size > 0 ? Math.ceil(tbl.total_items / tbl.page_size) : 1;
			setTotalPages(tp);
		}
	};

	const gotoPage = async n => {
		if (n < 1 || (!!totalPages && n > totalPages)) {
			return;
		}
		const reqData = {
			pageNum: n,
			pageSize,
			searchQuery,
			filters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData);
	};

	const changePageSize = async n => {
		setPageSize(n);
		const reqData = {
			pageNum,
			pageSize: n,
			searchQuery,
			filters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData);
	};

	const changeSearchQuery = async (q, reload = true) => {
		q = q || null;
		if (q === searchQuery) {
			return;
		}
		setSearchQuery(q);
		if (!!reload) {
			const pn = !!infiniteScrolling ? 1 : pageNum;
			const reqData = {
				pageNum: pn,
				pageSize,
				searchQuery: q,
				filters,
				sortBy,
				addtionalParams,
			};
			return await fetchData(reqData, true);
		}
	};

	const changeSortBy = async sb => {
		setSortBy(sb);
		const pn = !!infiniteScrolling ? 1 : pageNum;
		const reqData = {
			pageNum: pn,
			pageSize,
			searchQuery,
			filters,
			sortBy: sb,
			addtionalParams,
		};
		return await fetchData(reqData, true);
	};

	const changeFilters = async f => {
		setFilters(f);
		const pn = !!infiniteScrolling ? 1 : pageNum;
		const reqData = {
			pageNum: pn,
			pageSize,
			searchQuery,
			filters: f,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData, true);
	};

	const addAdditionalFilters = async (f, ps) => {
		const nfilters = [...filters];
		const existingObjIndex = nfilters.findIndex(
			obj => obj.property === f.property,
		);
		if (existingObjIndex !== -1) {
			//if property already exists
			nfilters[existingObjIndex].value = [...f.value];
			nfilters[existingObjIndex].labels = [...f.labels];
		} else {
			nfilters.push(f);
		}
		setFilters(nfilters);

		const pn = !!infiniteScrolling ? 1 : pageNum;
		const reqData = {
			pageNum: pn,
			pageSize,
			searchQuery,
			filters: nfilters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData, true);
	};

	const removeFilter = async f => {
		const nfilters = [...filters];

		for (let i = 0; i < nfilters.length; i++) {
			if (nfilters[i].property === f.property) {
				nfilters[i].value = nfilters[i].value.filter(
					value => !f.value.includes(value),
				);
				nfilters[i].labels = nfilters[i].labels.filter(
					label => !f.labels.includes(label),
				);
				if (nfilters[i].value.length === 0 && nfilters[i].labels.length === 0) {
					nfilters.splice(i, 1);
				}
			}
		}
		setFilters(nfilters);

		const pn = !!infiniteScrolling ? 1 : pageNum;
		const reqData = {
			pageNum: pn,
			pageSize,
			searchQuery,
			filters: nfilters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData, true);
	};

	const gotoNextPage = async () => {
		if (pageNum >= totalPages) {
			return;
		}
		const reqData = {
			pageNum: pageNum + 1,
			pageSize,
			searchQuery,
			filters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData);
	};

	const gotoPrevPage = async () => {
		if (pageNum <= 1) {
			return;
		}
		const reqData = {
			pageNum: pageNum - 1,
			pageSize,
			searchQuery,
			filters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData);
	};

	const gotoFirstPage = async () => {
		const reqData = {
			pageNum: 1,
			pageSize,
			searchQuery,
			filters,
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData, !!infiniteScrolling);
	};

	const reloadPage = async () => {
		const reqData = {
			pageNum,
			pageSize,
			searchQuery: "",
			filters: [],
			sortBy,
			addtionalParams,
		};
		return await fetchData(reqData, true);
	};

	const clearItems = () => {
		setPageNum(1);
		setTotalItems(0);
		setSearchQuery(null);
		setItems([]);
		setSelectedIndexes([]);
	};

	const onRowSelectionChanged = (rowIdx, isSelected) => {
		let newIndexes = selectedIndexes.filter(i => i !== rowIdx);
		if (!!isSelected) {
			newIndexes.push(rowIdx);
		}
		setSelectedIndexes(newIndexes);
	};

	const onHeaderSelectionChanged = isSelected => {
		const newIndexes = !!isSelected ? [...Array(items.length).keys()] : [];
		setSelectedIndexes(newIndexes);
	};

	const updateLocalItem = (itemSelector, newItem) => {
		setItems(items => {
			const item = itemSelector(items);
			if (!item) {
				return items;
			}
			return items.map(it => (it === item ? newItem : it));
		});
	};

	const removeLocalItem = itemSelector => {
		setItems(items => {
			const item = itemSelector(items);
			if (!item) {
				return items;
			}
			return items.filter(it => it !== item);
		});
	};

	const changeAddtionalParams = async (
		data,
		newPn = null,
		overrideItems = true,
	) => {
		if (!data) return;

		setAdditionalParams(data);
		const pn = data.hasOwnProperty("in_stock") ? 1 : pageNum;
		const updatedParams = {
			pageNum: newPn ? newPn : pn,
			pageSize: pageSize,
			searchQuery,
			filters,
			sortBy,
			addtionalParams: data,
		};

		return await fetchData(updatedParams, overrideItems);
	};

	const updateQueryParam = () => {
		if (!withInitialFilters) {
			// we dont want to update query if we are not using initial filters
			return;
		}
		const newQuery = { ...router.query };
		// Remove the dynamic `id` from the query if it's present
		delete newQuery.id;
		delete newQuery.short_code;
		if (filters.length) {
			newQuery.filters = filters
				.map(
					q =>
						(mappedFilters[q.property] ?? q.property) +
						"^" +
						q.condition +
						"^" +
						q.value,
				)
				.join(";");
		} else {
			delete newQuery.filters;
		}

		if (searchQuery?.length) {
			newQuery.query = searchQuery;
		} else {
			delete newQuery.query;
		}

		if (layout === "grid" || layout === "map") {
			newQuery.layout = layout;
		} else {
			delete newQuery.layout;
		}

		if (sortBy?.length && !!sortBy[0]) {
			newQuery.sortBy = sortBy;
		} else {
			delete newQuery.sortBy;
		}

		if (
			!router.query.rtr &&
			!router.query.product_ids &&
			!router.query.modal &&
			!router.query.postId &&
			!router.query.postType
		) {
			router.replace(
				{
					pathname: router.asPath.split("?")[0],
					query: newQuery,
				},
				undefined,
				{ shallow: true },
			);
		}
	};

	useEffect(() => {
		if (!isFirstLoad) {
			updateQueryParam();
		}
	}, [filters, searchQuery, isFirstLoad, layout, sortBy]);

	const changeLayout = async () => {
		if (layoutOptions.length === 1) return;

		// let nextLayout =
		// 	layout === layoutOptions[0] ? layoutOptions[1] : layoutOptions[0];
		// // If switching to "map", set page size to 1000 and fetch data
		// if (nextLayout === LAYOUT.MAP) {
		// 	await changePageSize(1000);
		// }
		// // Revert page size after map
		// else if (pageSize === 1000) {
		// 	await changePageSize(25);
		// }

		setLayout(prevLayout =>
			prevLayout === layoutOptions[0] ? layoutOptions[1] : layoutOptions[0],
		);
	};

	useEffect(() => {
		if (router.query?.layout) {
			if (layoutOptions.includes(router.query.layout)) {
				setLayout(router.query.layout);
			}
		}
	}, [router.pathname]);

	return {
		ready,
		loading,
		error,
		items,
		pageNum,
		pageSize,
		searchQuery,
		filters,
		sortBy,
		totalItems,
		totalPages,
		selectedIndexes,
		selectedItems,
		infiniteScrolling,
		lastUpdateAt,
		layout,
		layoutOptions,
		addtionalParams,

		changeLayout,
		setFilters,
		gotoPage,
		changePageSize,
		changeSearchQuery,
		changeSortBy,
		changeFilters,
		addAdditionalFilters,
		removeFilter,
		gotoNextPage,
		gotoPrevPage,
		gotoFirstPage,
		reloadPage,
		clearItems,
		onRowSelectionChanged,
		onHeaderSelectionChanged,
		updateLocalItem,
		removeLocalItem,
		changeAddtionalParams,
	};
}

const buildRequestData = ({
	pageNum,
	pageSize,
	searchQuery,
	filters,
	sortBy,
	addtionalParams,
}) => {
	let tbl = {};
	if (!!pageNum) {
		tbl.pn = pageNum;
	}
	if (!!pageSize) {
		tbl.ps = pageSize;
	}
	if (!!searchQuery) {
		tbl.q = searchQuery;
	}
	if (!!filters && !!filters.length) {
		tbl.f = sanitizeFilterValues(filters);
	}
	if (!!sortBy && !!sortBy.length) {
		tbl.sb = sortBy;
	}
	return { tbl, ...addtionalParams };
};
